import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import Seo from 'components/Seo';

export default function PageIndex() {
  return (
    <Layout>
      <Seo title="DUNCKELFELD" keywords={['agility', 'scrum', 'react']} />

      <h1>
        Join us on our
        <br />
        Agility Journey
      </h1>
      <h2>Whoops, this is minimalistic.</h2>

      <p>
        Building a good product means to say no. Thus focus is the prime
        directive. Start simple and never stop improving.
      </p>
      <p>
        Developing a digital product is a process and <em>not</em> a project. It
        has no defined end.
      </p>

      <h2>Practice what you preach.</h2>
      <p>
        One update per week: Our challenge is to breathe live into this site.
        From now &apos;til forever.
      </p>
      <p>
        The best motivation is to be transparent with this:{' '}
        <Link to="/changelog/">The changelog does not lie.</Link>
      </p>

      <h2>Be our guide.</h2>
      <p>
        Do you have 5 minutes? Please help us to build the <em>right</em>{' '}
        things.{' '}
        <a href="mailto:mail@dunckelfeld.de">
          Hit us up if you have any ideas or feedback about the site.
        </a>
      </p>

      <h2>Curious about our skills?</h2>
      <p>New case studies are coming your way.</p>
      <p>
        For the good old times&apos; sake you can browse{' '}
        <a href="https://static.dunckelfeld.de/en">
          our case studies on our legacy website.
        </a>
      </p>

      <h2>Contact.</h2>
      <p>
        <a href="mailto:neu@dunckelfeld.de">Project inquieries</a> |{' '}
        <a href="mailto:jobs@dunckelfeld.de">Job applications</a> |{' '}
        <a href="mailto:mail@dunckelfeld.de">Contact</a>
      </p>
    </Layout>
  );
}
